class HubspotTrackingApi {

    constructor() {
        this._hsq = (window._hsq = window._hsq || []);
    }

    sendIdentificationEvent(id, email) {
        this._hsq.push(['identify',
            {
                id: id,
                email: email
            },
        ]);
        this._hsq.push(['setPath', window.location.href]);
        this._hsq.push(['trackPageView']);
    }
}