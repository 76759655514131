function project_set_user(project_id, user_id) {

    jQuery('#' + 'manageproject_loader_' + project_id).show();
    jQuery('#' + 'manageproject_item_' + project_id).hide();

    jQuery.post('/project/ajax.php',
        'action=set_user&project_id=' + project_id + '&user_id=' + user_id,
        function (r) {
            if (r == "REMOVE") {
                project_refresh_project_list(0);
            }
            else {
                jQuery('#' + 'manageproject_' + project_id).html(r);
            }
        });
}


function project_hour_rate_on_request_onchange() {
    var obj = jQuery('#' + 'hour_rate');

    if (jQuery('#' + 'hour_rate_on_request').checked) {
        obj.prop('disabled', true);
        jQuery(obj).addClass('disabled');
        jQuery(obj).css('background-color', '#f2f2f2');
        jQuery(obj).css('color', '#333333');
    } else {
        obj.prop('disabled', false)
        jQuery(obj).removeClass('disabled');
        jQuery(obj).css('background-color', '#fff');
        jQuery(obj).css('color', '#333333');
    }
}


function project_change_contact_form_div(project_id) {

    var myval = jQuery('#contact_user_id').val();
    var elementId = '#edit_contactdata_long';

    if (myval == 0) {
        jQuery(edit_contactdata_long).show();
    } else {
        jQuery(edit_contactdata_long).hide();
    }

    return false;
}

function project_update_contact_form(user_id, project_id) {
    jQuery('#edit_contactdata_long').load('/profile_staff/ajax.php?action=update_contact_form&id=' + user_id + '&project_id=' + project_id);
}


function project_refresh_project_list(offset) {
    jQuery('#marker_projects').load('/project/ajax.php',
        urlParamsToObject('action=refresh_project_list&_offset=' + offset));
}


function project_delete_confirmation(id, offset) {
    var button = {label: 'löschen', onclick: 'project_delete(' + id + ', ' + offset + '); modal.hide();'};

    modal.show_message($T('project:delete_project'), 1000000, 'info', 'Abbrechen', button);
}


/**
 * delete a project and refresh the complete list
 */
function project_delete(id, offset) {
    jQuery.post('/project/ajax.php',
        {
            action: 'delete',
            id: id
        },
        function (r) {
            project_refresh_project_list(offset);
        });
}


/**
 * set a project to offline
 */
function project_set_offline(id, offset) {
    jQuery.post('/project/ajax.php', {action: 'set_online_offline', id: id}, function (r) {
        if (r != "") {
            modal.show_message(r, 1000000, 'error');
        } else {
            project_refresh_element(id, offset);
        }
    }, "text");
}

function project_refresh_element(id, offset) {
    jQuery('#manageproject-container_'+  id).load(
        '/project/ajax.php',
        {
            action: 'get_project_item',
            id: id,
            offset: offset
        });
}

function project_show_update_staffing_status(id) {
    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=not_staffed_status&project_id=' + id,
        'Status aktualisieren'
    );
}

function project_show_update_staffing_status_staffed_by_booking(id) {
    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=project_show_update_staffing_status_staffed_by_booking&project_id=' + id,
        'Projekt offline'
    );
}

function project_update_staffing_status(id, status_id, offset) {
    modal.hide();
    window.setTimeout(function () {
        jQuery.post('/project/ajax.php',
            {
                action: 'update_staffing_status',
                id: id,
                status_id: status_id
            },
            function (r) {
                var j = r;
                if (j["error"] == 0) {
                    if (j["project_online"] == 1) {
                        project_set_offline(id, offset);
                    } else {
                        project_refresh_element(id, offset);
                    }
                    if (status_id == 4) {
                        // if not staffed via freelance.de show another modal
                        modal.showFLPopup(
                            'request',
                            '/ajax/popup_template.php',
                            'action=not_staffed_reason&project_id=' + id,
                            'Status aktualisieren'
                        );
                    }
                } else {
                    modal.show_message(j["result_message"], 1000000, 'error');
                }
            }, 'json');
    }, 200);
}

function project_set_internal_staffing_status(id, status_id) {
    modal.hide();
    window.setTimeout(function () {
        jQuery.post('/project/ajax.php',
            {
                action: 'set_internal_staffing_status',
                id: id,
                status_id: status_id
            },
            function (r) {
                var j = r;
                if (j["error"] == 0) {
                } else {
                    modal.show_message(j["result_message"], 1000000, 'error');
                }
            }, 'json');
    }, 200);
}


/**
 * set a project to online
 */
function project_set_online(id, redirect) {
    redirect = (typeof redirect == 'undefined') ? null : redirect;

    jQuery.post('/project/ajax.php',
        {action: 'set_online_offline', id: id},
        function (r) {
            if (r != "") {
                modal.show_message(r, 1000000, 'error');
            } else {
                if (redirect != null) {
                    document.location.href = redirect;
                }

                project_refresh_element(id, 0);
            }
        }, "text");
}

/**
 * saves the overview part of a project
 */
function save_project(form_name, div_name, set_online, ignore_alias) {
    var locations = get_list_of_locations();

    if (typeof set_online == 'undefined') {
        set_online = 1;
    }

    if (typeof ignore_alias == 'undefined') {
        ignore_alias = 0;
    }

    var src = jQuery(".js-company-logo").attr("src");
    var show_matched_profiles = false;
    var show_update_logo_modal = false;
    if (typeof src != "undefined") {
        show_update_logo_modal = src.includes("nobody");
    }

    jQuery.post('/project/create.php',
        jQuery('#' + form_name).serialize() + '&locations=' + JSON.stringify(locations) + '&set_online=' + set_online + '&ignore_alias=' + ignore_alias,
        function (transport) {
            var j = transport;

            // Error in form
            if (j["error"] == 1) {
                jQuery("#preview_project").hide();
                jQuery("#"+div_name).show();
                jQuery('#' + div_name).html(j["result_html"]);
                flScrollTo(div_name);
                initMaxLengthTextArea();
                toggleZipField();
            } else if (j["error"] == 2) {
                toggleZipField();
                var button = {label: 'Projekt speichern', onclick: function () { save_project(form_name,div_name,0,1); } };
                modal.show_message('Ein Projekt mit dieser Referenznummer existiert bereits. Bitte bestätigen Sie, dass das Projekt gespeichert werden muss.', 1000000, 'info', 'Abbrechen', button, function () {document.location.href = j["redirect_url"]; } );
            } else {
                // the window is now modal, the user should choose a button and cannot close the window without clicking on a button
                modal.block_page = true;

                if (typeof j["recommended_profiles"] !== "undefined" && typeof j["recommended_profiles"]["number"] !== "undefined" && j["recommended_profiles"]["number"] > 0 ) {
                    show_matched_profiles = true;
                }
                if (j['preview']) {
                    show_project_preview(j['result_html'],"preview_project_content","preview_project",div_name);
                } else if (j["project"]["status"] == 'offline' && set_online == false && j["project"]["offline_reason"] != "cac_expected") {
                    show_modal_set_online(j["project"]["id"],j["redirect_url"],show_update_logo_modal, show_matched_profiles, j);
                } else if (show_matched_profiles) {
                    show_modal_save_successful(0, j["redirect_url"], j["recommended_profiles"]["number"], j["recommended_profiles"]["url"], j["recommended_profiles"]["button_label"]);
                } else if (show_update_logo_modal) {
                    show_modal_upload_logo(j["redirect_url"]);
                } else {
                    show_modal_save_successful(j["booking_user"], j["redirect_url"], j["recommended_profiles"]["number"], j["recommended_profiles"]["url"], j["recommended_profiles"]["button_label"]);
                }
            }

            if (j["error"] > 0) {
                initDatePicker();
                jQuery('#hidden-form-fields').collapse("show");
            }

        }, 'json');
}

function show_project_preview(preview_data, preview_element, preview_container, form_container) {
    jQuery("#"+preview_element).html(preview_data);
    jQuery("#"+form_container).hide();
    jQuery("#"+preview_container).show();
    flScrollTo(preview_container);
}

function show_modal_set_online(project_id,redirect_url,show_update_logo_modal, show_matched_profiles, j) {
    var message = 'Das Projekt wurde erfolgreich gespeichert, aber es ist noch offline. Möchten sie es online schalten?';
    var secondsToWait = 1000000;
    var type = "info";
    var standard_button_label = 'Nein';
    var standard_button_label_callback = function () { document.location.href=redirect_url; };
    var button = {
        label: 'Projekt online schalten',
        onclick: function () { project_set_online(project_id,redirect_url,false); }
    };

    if (show_matched_profiles) {
        standard_button_label_callback = function () {
            modal.block_page = false;
            modal.hide();
            setTimeout(function() {
                modal.block_page = true;
                show_modal_save_successful(j["booking_user"], j["redirect_url"], j["recommended_profiles"]["number"], j["recommended_profiles"]["url"], j["recommended_profiles"]["button_label"]);
            }, 1000);
        };
        button.onclick = function () {
            modal.block_page = false;
            modal.hide();
            setTimeout(function() {
                modal.block_page = true;
                project_set_online(project_id,null,false);
                show_modal_save_successful(j["booking_user"], j["redirect_url"], j["recommended_profiles"]["number"], j["recommended_profiles"]["url"], j["recommended_profiles"]["button_label"]);
            }, 1000);
        };
    }

    else if (show_update_logo_modal) {
        standard_button_label_callback = function () { modal.block_page = false; modal.hide(); setTimeout(function() { modal.block_page = true; show_modal_upload_logo(redirect_url); }, 1000); };
        button.onclick = function () { modal.block_page = false; modal.hide(); setTimeout(function() { modal.block_page = true; project_set_online(project_id,null,false); show_modal_upload_logo(redirect_url); }, 1000); };
    }

    modal.show_message(message,secondsToWait,type,standard_button_label,button,standard_button_label_callback);
}

function show_modal_save_successful(booking_user, redirect_url, recommended_profiles_number, recommended_profiles_url, recommended_profiles_button) {
    var secondsToWait = 3000;
    var buttonLabel = "Ok";
    var customButton = null;
    if (recommended_profiles_number > 0) {
        secondsToWait = 0;
        customButton = {};
        customButton.onclick = function () { modal.hide(); document.location.href=recommended_profiles_url; return false; };
        customButton.label = recommended_profiles_button;
    }

    if (booking_user == 1) {
        document.location.href = redirect_url;
        return;
    }

    modal.show_message('Das Projekt wurde erfolgreich gespeichert', secondsToWait, 'success', buttonLabel, customButton, 'modal.hide(); document.location.href = "' + redirect_url + '";');
}

function show_modal_upload_logo(redirect_url) {
    var message = "Damit Ihr Projekt mehr Aufmerksamkeit und Bewerbungen erhält, laden Sie bitte Ihr Firmenlogo hoch. Alternativ schicken Sie Ihr Logo an support@freelance.de, dann übernehmen wir das gern für Sie.";
    var secondsToWait = 1000000;
    var type = "info";
    var standard_button_label = "Ok";
    var standard_button_label_callback = function () { modal.hide(); document.location.href=redirect_url; };
    var button = {label: "Zum Firmenprofil",onclick: function () { modal.hide(); document.location.href='/profile_pa/index.php'; return false; } };
    modal.show_message(message,secondsToWait,type,standard_button_label,button,standard_button_label_callback);
}

/**
 * Performs all the steps for the import of a guest user and his project
 */
function import_project_by_guest_user(form_name) {
    if (jQuery('#location_container').length) {
        var locations = get_list_of_locations();
    }

    jQuery.post('/project/import_as_guest.php',
        jQuery('#' + form_name).serialize() + '&locations=' + JSON.stringify(locations),
        function (transport) {
            var j = transport;

            if (j['redirect'] != undefined)
            {
                window.location = j['redirect'];
            }
            else
            {
                jQuery('#new_project_box').html(j['content']);
                jQuery('html, body').animate({ scrollTop: jQuery('html').offset().top }, 500);
            }
        }, 'json');
}

/**
 * deletes the photo from a project
 */
function project_delete_photo(id) {
    if (confirm($T("project:delete_photo"))) {
        jQuery.post('/project/create.php?action=delete_photo&id=' + id);
        jQuery('#projectpicture').remove();
        jQuery('#' + 'companypicture').show();
    }
}


/**
 * updates the picture marker with the form fields
 */
function project_update_picture(id, error_id) {
    if (error_id > 0) {
        alert($T('global_error:' + error_id));
    }
    else {
        // if there is a newly created project, then save the project id
        if (jQuery('#' + 'new_project_id')) {
            jQuery('#' + 'new_project_id').val(id);
        }

        // update the picture frame
        jQuery('#marker_picture').load('/project/create.php?action=show_picture_form&id=' + id);
    }
}

/**
 * Send project to buddy
 * @param form_id The html of the form which is send
 */
function send_project_to_friend(form_id) {
    jQuery.post('/project/ajax.php?action=send_project&' + jQuery('#' + form_id).serialize(),
        function (r) {
            var j = r;
            if (j['error'] === 0) {
                modal.show_message(j["result_message"], 5000, 'success');
            } else {
                jQuery('#' + form_id).html(j["result_html"]);
            }
        },'json');
}

/**
 * Send an internal mail to a company
 */
function internal_mail_project_write(save_message_box_name) {
    jQuery.post('/project/ajax.php?action=send_internal_mail_to_project&' + jQuery('#' + 'internal_mail_form').serialize(),
        function (result) {

            var fade = setTimeout("fadeout('" + save_message_box_name + "')", 3500);
            var hide = setTimeout("jQuery('#' + " + save_message_box_name + "').hide()", 4900);

        }
    );
}


/**
 * Report a project
 */
function report_project_by_user() {
    jQuery.post('/project/ajax.php?action=report_project&' + jQuery('#' + 'report_project_form').serialize(),
        function (result) {
            var json = result;
            if (json['error'] === 0) {
                modal.show_message(json["result_message"], 5000, "success");
            } else {
                jQuery('#report_project_form').html(json['result_html']);
            }
        }, 'json');
}

/**
 * Manage report project check options
 */
function manage_report_project_options() {
    var checked_value = jQuery('input.radioelement[name="report_project_option"]:checked').val();

    if (checked_value == 3) {
        jQuery('#report_project_reason').hide();
        jQuery('#report_duplicate_project').show();
    } else {
        jQuery('#report_project_reason').show();
        jQuery('#report_duplicate_project').hide();
    }
}

/**
 * This function counts how often the contact data was shown
 */
function count_shown_contactdata_for_project(project_id, user_id, hide_element) {
    jQuery.post('/project/ajax.php',
        'action=count_shown_contactdata_for_project&project_id=' + project_id + '&user_id=' + user_id,
        function (result) {
            jQuery('#contact_data').show();
            jQuery(hide_element).remove();
        });
}


/**
 * This function opens the print view
 */
function open_print_view_project(project_id, user_id) {
    jQuery.post('/project/ajax.php',
        'action=open_print_view&project_id=' + project_id + '&user_id=' + user_id,
        function (result) {

            var json = result;

            var url = json["url"];

            var windowName = "_blank";

            //var windowSize = "width=800,height=800,scrollbars=yes";

            window.open(url, windowName);

        }, 'json');
}


function count_clicked_applicationlink_for_project(project_id, user_id, url) {
    jQuery.post("/project/ajax.php",
        'action=count_clicked_applicationlink_for_project&project_id=' + project_id + '&user_id=' + user_id,
        function (r) {
            if (url) {
                window.open(url);
            }
        });
}


function project_get_geo_data(text, city_id, zip_id, county_id, country_id, location_id) {
    if (jQuery('#' + city_id).length && jQuery('#' + zip_id).length && jQuery('#' + country_id).length && jQuery('#' + country_id).val() == 76) {
        jQuery.post('/project/ajax.php',
            'action=get_geo_data&q=' + text + '&location_id=' + location_id,
            function (transport) {
                var j = transport;
                if (j['error'] == 0) {
                    if (jQuery('#' + 'project_autocompletion').length) {
                        jQuery('#' + 'project_autocompletion').html(j['html_result']);
                    }

                    project_set_county(j['result'][0]['county'], county_id);
                }
            }, 'json');
    }
    else if (jQuery('#' + country_id).length && jQuery('#' + country_id).val() != 76 && jQuery('#' + zip_id).length && jQuery('#' + zip_id).selectedIndex.length) {
        project_reset_geo_data_fields(city_id, zip_id, city_id);
    }
}

function project_set_county(county_value, county_id) {
    if (county_value.length && county_value > 0 && jQuery('#' + county_id).length) {
        for (i = 0; i < jQuery('#' + county_id).options.length; i++) {
            if (jQuery('#' + county_id).options[i].val() == county_value) {
                jQuery('#' + county_id).selectedIndex = i;
                break;
            }
        }
    }
}

function project_reset_geo_data_fields(city_id, zip_id, focus_field_id) {
    if (jQuery('#' + city_id).length && jQuery('#' + zip_id).length) {
        var city = jQuery('#' + city_id).val();
        var zip  = jQuery('#' + zip_id).val();

        jQuery.post('/project/ajax.php',
            'action=get_geo_data_reset_fields&q=',
            function (transport) {
                var j = transport;

                if (jQuery('#' + 'project_autocompletion').length) {
                    jQuery('#' + 'project_autocompletion').html(j['html_result']);
                }
            }, 'json');

        jQuery('#' + city_id).val(city);
        jQuery('#' + zip_id).val(zip);
        if (jQuery('#' + focus_field_id).length) {
            jQuery('#' + focus_field_id).focus();
        }
    }
}

var project_typing_time = null;
/**
 * handler for onkeyup, saves the last time when the user has anything typed
 */
function project_city_typing() {
    var start = false;

    if (project_typing_time == null) {
        start = true;
    }

    // refresh the time that shows that the user is still typing
    project_typing_time = (new Date()).getTime();

    if (start) {

        jQuery( "#" + 'city' ).keyup(function(event) {
            project_check_typing_key();
        });
        project_is_city_typing();
    }
}

/**
 * checks if the user is still typing
 */
function project_is_city_typing() {
    // time to wait for the user after he has finished typing
    var timeToWait = 1000;

    if (project_typing_time.length) {
        // the time now as unix timestamp
        var now = (new Date()).getTime();

        if (now - project_typing_time >= timeToWait) {
            // check what the user has typed
            project_get_geo_data_city();
            project_typing_time = null;
        }
        else {
            // execute myself after 1/2 sec
            setTimeout("project_is_city_typing()", 100);
        }
    }
}

/**
 * handler for onkeyup, saves the last time when the user has anything typed
 */
function project_zip_typing() {
    var start = false;

    if (project_typing_time == null) {
        start = true;
    }

    // refresh the time that shows that the user is still typing
    project_typing_time = (new Date()).getTime();

    if (start) {

        jQuery( "#" + 'zipcode' ).keyup(function(event) {
            project_check_typing_key();
        });

        project_is_zip_typing();
    }
}

/**
 * checks if the user is still typing
 */
function project_is_zip_typing() {
    // time to wait for the user after he has finished typing
    var timeToWait = 1000;

    if (project_typing_time.length) {
        // the time now as unix timestamp
        var now = (new Date()).getTime();

        if (now - project_typing_time >= timeToWait) {
            // check what the user has typed
            project_get_geo_data_zip();
            project_typing_time = null;
        }
        else {
            // execute myself after 1/2 sec
            setTimeout("project_is_zip_typing()", 100);
        }
    }
}

function project_check_typing_key(event) {
    if (Event.KEY_ESC == event.keyCode) {
        project_typing_time = null;
        if (jQuery('#' + 'project_city_autocompletion').length) {
            jQuery('#' + 'project_city_autocompletion').hide();
            jQuery('#' + 'project_city_autocompletion').html('');

            return false;
        }
    }
}

function project_get_geo_data_city() {
    if (jQuery('#' + 'city').length && jQuery('#' + 'city').val() != '') {
        jQuery.post('/project/ajax.php',
            'action=get_geo_data_city_completion&q=' + jQuery('#' + 'city').val(),
            function (transport) {
                var j = transport;

                if (jQuery('#' + 'project_city_autocompletion').length) {
                    if (j['error'] == 0) {
                        jQuery('#' + 'project_city_autocompletion').show();
                        jQuery('#' + 'project_city_autocompletion').html(j['html_result']);
                    } else {
                        jQuery('#' + 'project_city_autocompletion').hide();
                        jQuery('#' + 'project_city_autocompletion').html('');
                    }
                }
            }, 'json');
    }
    else {
        project_reset_geo_data_fields('city', 'zipcode', 'city');
    }
}

function project_get_geo_data_zip() {
    if (jQuery('#' + 'zipcode').length && jQuery('#' + 'zipcode').val() != '') {
        jQuery.post('/project/ajax.php',
            'action=get_geo_data_zip_completion&q=' + jQuery('#' + 'zipcode').val(),
            function (transport) {
                var j = transport;

                if (jQuery('#' + 'project_zip_autocompletion').length) {
                    if (j['error'] == 0) {
                        jQuery('#' + 'project_zip_autocompletion').show();
                        jQuery('#' + 'project_zip_autocompletion').html(j['html_result']);
                    } else {
                        jQuery('#' + 'project_zip_autocompletion').hide();
                        jQuery('#' + 'project_zip_autocompletion').html('');
                    }
                }
            }, 'json');
    }
    else {
        project_reset_geo_data_fields('city', 'zipcode', 'zipcode');
    }
}


function bizcon_activate_project_export(iface_user_id, project_settings_id) {
    jQuery('.tooltip').hide();

    jQuery.ajax({
        type: 'POST',
        url: '/project/ajax.php',
        data: {
            action: 'bizcon_activate_project_export',
            iface_user_id: iface_user_id,
            project_settings_id: project_settings_id
        },
        dataType: 'json',
        success: function (json) {
            if (json['html'] !== "") {
                jQuery('#iface_user_' + iface_user_id).html(json['html']);
            }
        }
    });
}

function bizcon_deactivate_project_export(iface_user_id, project_settings_id) {
    jQuery('.tooltip').hide();

    jQuery.ajax({
        type: 'POST',
        url: '/project/ajax.php',
        data: {
            action: 'bizcon_deactivate_project_export',
            iface_user_id: iface_user_id,
            project_settings_id: project_settings_id
        },
        dataType: 'json',
        success: function (json) {
            if (json['html'] !== "") {
                jQuery('#iface_user_' + iface_user_id).html(json['html']);
            }
        }
    });
}

/**
 *  Save iface user item
 *
 * @param iface_user_id
 * @param form_box_element
 * @param form_element
 * @param iface_user_main_element
 * @return
 */
function bizcon_save_iface_user_item(iface_user_id, project_settings_id, status_value, open_box_id, iface_user_main_element) {
    var i_object_id     = project_settings_id;
    var i_iface_user_id = iface_user_id;
    var o_object        = iface_user_main_element;


    jQuery.post('/project/ajax.php',
        {
            action: 'save_iface_user_item',
            iface_user_id: iface_user_id,
            status_value: status_value,
            project_settings_id: i_object_id
        },
        function (t) {
            if (jQuery('#' + o_object).length) {
                // Just update the item
                bizcon_refresh_iface_user_item(i_iface_user_id, i_object_id, jQuery('#' + o_object), open_box_id);
            }
        });
}


/**
 * Update the iface user item
 *
 * @param iface_user_id
 * @param project_settings_id
 * @param element
 * @param open_box_id
 * @return
 */
function bizcon_refresh_iface_user_item(iface_user_id, project_settings_id, element, open_box_id) {
    var o_element = element;

    jQuery.post('/project/ajax.php',
        {
            action: 'refresh_iface_user_item',
            iface_user_id: iface_user_id,
            project_settings_id: project_settings_id,
            open_box_id: open_box_id
        },
        function (t) {
            o_element.html(t);
        });
}


function project_redirect_to_marked_projects(url) {
    if (url != '') {
        document.location.href = url;
    }
}

function bind_load_project_photos(args) {

    start_load_project_photos();

    // the viewport
    jQuery("#project_list img:in-viewport").each(function (index) {
        if (jQuery(this).length) {
            if (jQuery(this).hasClass("project_photo_thumbnail") && !jQuery(this).hasClass("project_photo_thumbnail_viewport")) {
                jQuery(this).addClass('project_photo_thumbnail_viewport');
                check_load_project_photos(args, this, 'thumbnail');
            }
        }
    });

    jQuery(window).bind('scroll', function () {

        // the viewport
        jQuery("#project_list img:in-viewport").each(function (index) {

            if (jQuery(this).length) {
                if (jQuery(this).hasClass("project_photo_thumbnail") && !jQuery(this).hasClass("project_photo_thumbnail_viewport")) {
                    jQuery(this).addClass('project_photo_thumbnail_viewport');
                    check_load_project_photos(args, this, 'thumbnail');
                }
            }
        });

        // the low part
        jQuery("#project_list img:below-the-fold").each(function (index) {
            if (jQuery(this).length) {
                if (jQuery(this).hasClass("project_photo_thumbnail") && !jQuery(this).hasClass("project_photo_thumbnail_viewport")) {
                    jQuery(this).addClass('project_photo_thumbnail_viewport');
                    check_load_project_photos(args, this, 'thumbnail');
                }
            }
        });
    });
}

function check_load_project_photos(args, object, type) {
    var prefix = 'project_photo_thumbnail_';
    var id     = jQuery(object).attr('id').substring(prefix.length);

    jQuery.post('/project/ajax.php',
        'action=get_project_photo&type=' + type + '&args=' + args + '&project_id=' + id + '&widht=' + 40 + '&height=' + 40,
        function (t) {
            var j = t;
            if (j['error'] == 0) {
                // set the visible image
                element = jQuery('#' + 'project_photo_thumbnail_' + j['project_id']);
                if (element.length && j["result"] != '') {
                    element.src = j["result"];
                }

                element = null;

                // set the overlay image
                element = jQuery('#' + 'project_photo_overlay_' + j['project_id']);
                if (element.length && j["result_overlay"] != '') {
                    element.src = j["result_overlay"];
                }
            }
        }, 'json');
}

function start_load_project_photos() {
    jQuery.post('/project/ajax.php','action=start_load_project_photos&load_project_photos_max=5');
}

function stop_load_project_photos() {

    jQuery.post('/project/ajax.php', 'action=stop_load_project_photos');
}

function get_list_of_locations() {
    var a_locations = {};

    // gets each location element to get the infos in an array
    jQuery('#' + 'location_container > .project-location-container-item').each(function (i) {
        var location = {};

        location['zip_id']  = encodeURIComponent(jQuery(this).children('.hidden').html());
        location['zip']     = encodeURIComponent(jQuery(this).children('.zip').html());
        location['area']    = encodeURIComponent(jQuery(this).children('.area').html());
        location['city']    = encodeURIComponent(jQuery(this).children('.city').html());
        location['county']  = encodeURIComponent(jQuery(this).children('.county').html());
        location['country'] = encodeURIComponent(jQuery(this).children('.country').html());

        a_locations[i] = location;
    });
    return a_locations;
}

function update_offers() {
    if (jQuery('#projecttype').val() == 1) {
        jQuery('#offers_box').show();
    } else {
        jQuery('#offers_box').hide();
        jQuery('#offers').prop('checked', false);
    }
}


/**
 * Offered project accept click
 */
function offered_project_accept_click(confirmation_message, confirm_button_label, cancel_button_label, token) {
    var onclick = "offered_project_accept('" + token + "'); modal.hide();";

    modal.show_message(confirmation_message, '1000000', 'info', cancel_button_label,
        {'label': confirm_button_label, 'onclick': onclick});
}

function offered_project_display_reject_reasons(token, title) {
    modal.showFLPopup('request', '/ajax/popup_template.php', 'action=offered_project_reject_reasons&' + token, title);
}

/**
 * Offered project reject confirm
 *
 * @param confirmation_message
 * @param confirm_button_label
 * @param cancel_button_label
 * @param token
 */
function offered_project_reject_click(confirmation_message, confirm_button_label, cancel_button_label, token) {
    // I dont want to get 3 ajax translations, so the strings are get from backend
    var onclick = "offered_project_reject('" + token + "'); modal.hide();";

    modal.show_message(confirmation_message, '1000000', 'info', cancel_button_label,
        {'label': confirm_button_label, 'onclick': onclick});
}

function offered_project_accept(token) {
    jQuery.post('/project/ajax.php', {
            'action': 'accept_offered_project',
            'token': token,
        },
        function (response) {
            modal.hide();
            jQuery("#response_message").removeClass("alert-success").removeClass("alert-warning");
            if (response.error === 0) {
                jQuery("#response_message")
                    .addClass("alert-success")
                    .show()
                    .find("span").html(response.message);

                jQuery("#offered-project-actions").hide();
            } else {

                jQuery("#response_message")
                    .addClass("alert-warning")
                    .show()
                    .find("span").html(response.message);
            }
        },
        'json');

}

function offered_project_reject_action(token, reason, reason_text) {

    if (reason == 'not_available') {
        modal.hide();

        var availabilityModal = new FL_Modal();
        availabilityModal.onClickSelector = "#save";
        availabilityModal.onClickMethod = function() {
            offered_project_reject(token, reason, reason_text);
            availabilityModal.hide();
        };
        availabilityModal.setType('template');
        availabilityModal.setSource('items/project/modals/offered_project_update_availability.tpl');
        availabilityModal.setArgs('token='+token);
        availabilityModal.setTitle('Verfügbarkeit aktualisieren');
        // avoid timing issues
        window.setTimeout(function() {
            availabilityModal.showTemplate();
        }, 750);
        return;
    }

    offered_project_reject(token, reason, reason_text);

    return;
}

/**
 * Offered project reject action
 *
 * @param token
 */
function offered_project_reject(token, reason, reason_text) {

    jQuery.post('/project/ajax.php', {
            'action': 'reject_offered_project',
            'token': token,
            'reason': reason,
            'reason_text': reason_text
        },
        function (response) {
            modal.hide();
            jQuery("#response_message").removeClass("alert-success").removeClass("alert-warning");
            if (response.error === 0) {
                jQuery("#response_message")
                    .addClass("alert-success")
                    .show()
                    .find("span").html(response.message);

                jQuery("#offered-project-actions").hide();
            } else {

                jQuery("#response_message")
                    .addClass("alert-warning")
                    .show()
                    .find("span").html(response.message);
            }
        },
        'json');
}

function project_add_staffing_freelancer(profile_id) {

    let candidate_container = jQuery('#candidate_' + profile_id);
    candidate_container.toggleClass('selected');
    if (candidate_container.hasClass('selected')) {
        jQuery('#action_' + profile_id + ' > button').text('entfernen').removeClass('btn-success').addClass('btn-warning');
    } else {
        jQuery('#action_' + profile_id + ' > button').text("auswählen").removeClass('btn-warning').addClass('btn-success');
    }

    let is_selected = jQuery(".selected").length > 0;
    jQuery("button[name='select-applicant']").each(function (idx, elm) {
        jQuery(elm).prop("disabled", false);
        if (((profile_id == 0 && jQuery(elm).data("profile-id") != 0) || (profile_id != 0 && jQuery(elm).data("profile-id") == 0)) && is_selected) {
            jQuery(elm).prop("disabled", true);
        }
    });

    if (is_selected && jQuery("#save").data("error") === undefined) {
        jQuery("#save").prop("disabled", false);
    } else {
        jQuery("#save").prop("disabled", true);
    }
}

function save_staffing_freelancer(project_id) {
    jQuery("#save").prop("disabled", true);

    let url = [];

    jQuery(".selected").each(function(idx,elm) {
        url.push(jQuery(elm).data("url"));
    });

    if (url.length == 1 && jQuery(".selected").data("profile-id") == 0) {
        project_update_staffing_status(project_id, 4, 0);
        project_set_internal_staffing_status(project_id, 4);
        send_staffing_request_no_profile(project_id, url[0]);
    } else {
        url.push(jQuery("#save").data("url"));
        if (url.length) {
            removeEventListener("beforeunload", beforeUnloadListener);
            send_staffing_request(
                {error: [], url: url},
                project_id
            );
        }
    }
}

function send_staffing_request(data) {
    if (data.url.length) {
        jQuery.ajax({
            url: data.url.shift(),
            method: 'GET',
            dataType: 'json',
            success: function (json) {
                if (json['error'] != 0) {
                    data.error.push(json['result_message']);
                }
                send_staffing_request(data);
            }
        });
    } else {
        if (data.error.length) {
            jQuery("#save").data("error", data.error);
            modal.show_message(data.error.pop(), '1000000', 'error');
        } else {
            document.location.href = '/project/manage.php';
        }
    }
}

function send_staffing_request_no_profile(project_id, url) {
    removeEventListener("beforeunload", beforeUnloadListener);
    jQuery.ajax({
        url: url,
        method: 'GET',
        dataType: 'json',
        success: function (json) {
            if (false && json['error'] != 0) {
                jQuery("#save").data("error", json['result_message']);
                modal.show_message(json['result_message'], '1000000', 'error');
            } else {
                modal.showFLPopup(
                    'request',
                    '/ajax/popup_template.php',
                    'action=not_staffed_reason&project_id=' + project_id,
                    'Status aktualisieren'
                );
            }
        }
    });
}

function saveNotStaffedReason(button, projectId) {
    // find form for which button was pressed
    let form = jQuery(button).parents("form");
    // find all checked checkboxes
    let checkbox = form.find("input[name='reason-" + projectId + "']:checked");

    // if no checkbox is checked, do nothing
    if (checkbox.length != 1) {
        return;
    }

    // find URL for this option
    let url = form.find("input[id='"+checkbox.attr("id")+"-url']").val();
    if (checkbox.val() == 8) { // other reason, sent also a comment
        url += "&comment="+form.find("textarea[name='other-reason-text']").val();
    }

    // send request
    jQuery.ajax({
        url: url,
        method: 'GET',
        dataType: 'json',
        success: function (json) {
            // hide form
            modal.hide();
            form.data("opened", 0);

            // show error
            if (json['error'] != 0) {
                modal.show_message(json['result_message'], 1000000, 'error');
            } else {
                if (document.location.href.search('/project/manage.php') < 0) {
                    document.location.href = '/project/manage.php';
                }
            }
        }
    });
}

function showNotStaffedOtherReasonTextarea(elm) {
    let checkbox = jQuery(elm);
    if (checkbox.length > 0) {
        checkbox.parents("form").find("button[name='save']").prop("disabled", false);
        if (checkbox.val() == 8) {
            checkbox.parents("form").find("textarea").fadeIn();
        } else {
            checkbox.parents("form").find("textarea").fadeOut();
        }
    }
}


function giveFeedbackForDuplicateProject(source_project_id, duplicate_project_id, feedback_value) {
    jQuery.post('/project/ajax.php',
        'action=send_feedback_for_duplicate_projects&source_project_id=' + source_project_id + '&duplicate_project_id=' + duplicate_project_id + '&feedback_value=' + feedback_value,
        function (t) {
            var j = t;

            if (j['error'] == 0) {
                jQuery("#send_feedback_" + duplicate_project_id).hide();
            } else {
                modal.show_message(j['result_message'], 1000000, 'error');
            }
        }, 'json');
}


function updateInternalStuffingStatus() {
    let url = jQuery("#save").data("url");
    navigator.sendBeacon(url);
}


function aiPrompt(e) {
    var error = [];
    var locations = get_list_of_locations();

    var promptData = {
        current_status: "Open",
        project_title: jQuery("#project_title_new_project_box").val(),
        project_start: jQuery("#date_start").val(),
        expected_end: jQuery("#date_end").val(),
        application_deadline: jQuery("#auto_offline_date").val(),
        country: jQuery("#country").val(),
        project_location: locations,
        remote: jQuery("#remote").prop("checked"), // true
        budget: jQuery("#hour_rate").val().replace(",", "."),
        payment_category: jQuery("#hour_rate_unit").val(),
        required_qualifications: jQuery('select[name="skills_categories[]"]').val(),
        user_notes: jQuery("#user_notes").val(),
        form_unique_identifier: jQuery("#form_unique_identifier").val(),
    }

    jQuery("#project_title_new_project_box").removeClass('error');
    jQuery("#project_title_new_project_box").next('span').remove();
    jQuery("#date_end").removeClass('error');
    // jQuery("#date_end").next('span').remove();
    jQuery("#auto_offline_date").removeClass('error');
    // jQuery("#auto_offline_date").next('span').remove();
    jQuery("#hour_rate").removeClass('error');
    jQuery("#hour_rate").next('span').remove();

    jQuery("#reference_category_input").removeClass('error');
    jQuery("#reference_category_input").next('span').remove();

    if (promptData['project_title'] === "") {
        jQuery("#project_title_new_project_box").addClass('error');
        jQuery("#project_title_new_project_box").after('<span class="error-text">Das Feld darf nicht leer sein.</span>');
    }
    if (promptData['expected_end'] === "") {
        jQuery("#date_end").addClass('error');
        // jQuery("#date_end").after('<span class="error-text">Das Feld darf nicht leer sein.</span>');
    }
    if (promptData['application_deadline'] === "") {
        jQuery("#auto_offline_date").addClass('error');
        // jQuery("#auto_offline_date").after('<span class="error-text">Das Feld darf nicht leer sein.</span>');
    }
    if (promptData['budget'] === "") {
        jQuery("#hour_rate").addClass('error');
        jQuery("#hour_rate").after('<span class="error-text">Das Feld darf nicht leer sein.</span>');
    }

    if (promptData['required_qualifications'].length === 0) {
        jQuery("#reference_category_input").addClass('error');
        jQuery("#reference_category_input").after('<span class="error-text margin-bottom-md d-block">Bitte mindestens eine Qualifikation angeben.</span>');
        error.push("Missing value for qualification");
    }

    for (var k in promptData){
        if (k !== "user_notes") {
            if (promptData[k] === "") {
                error.push("Missing value for " + k);
            }
        }
    }

    if (error.length === 0) {
        console.log(promptData);

        jsonData = JSON.stringify(promptData);

        jQuery.ajax({
            type: 'POST',
            url: '/project/ajax.php',
            data: {
                action: "ai_prompt_description",
                prompt: jsonData,
            },
            dataType: 'json',
            success: function (json) {
                if (json["error"] === 1) {
                    console.log(json["message"]);
                } else if (json["error"] === 2) {
                    modal.show_message(json["message"], '1000000', 'error');
                } else {
                    // console.log("response ok");
                    if (jQuery("#description").hasClass("hidden")) {
                        jQuery('#description').removeClass('hidden');
                        jQuery('#description').prev('label').removeClass('hidden');
                        jQuery('#ai-button').addClass('hidden');
                        jQuery('#ai-button-re').removeClass('hidden');
                        jQuery('#ai-prompt-footer').removeClass('ai-prompt-position');
                    }
                    jQuery('#description').val(json["project_description"]);
                }
            }
        });


    } else {
        modal.show_message('Um die KI-Funktion zu nutzen, füllen Sie bitte alle rot markierten Felder aus.', '1000000', 'error');
    }
    e.preventDefault();
    return false;
}