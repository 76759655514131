var navigationCancel = 0;
var peCount = 0;
var adMovedByJS = false;

function modal_show(content, file, title, onClosed, hideClose)
{
	if(typeof onClosed != 'function') {
		onClosed = function () {};
	}
	
	if(file)
	{
		jQuery.post(file,
			function(r) {
				jQuery.fancybox({
					'speedIn'			: 200, 
					'speedOut'			: 200, 
					'content'			: r,
					'overlayShow'		: true,
					'overlayColor'		: '#fff',
					'title'				: title,
					'onClosed'			: onClosed,
					'showCloseButton'	: (!hideClose ? true : (hideClose ? false : true)),
					'modal'				: (!hideClose ? false : (hideClose ? true : false)),
					'centerOnScroll'	: true,
					'scrolling'			: 'no'
				});
			});
	}
	else
	{
		jQuery.fancybox({
			'speedIn'			: 600, 
			'speedOut'			: 200, 
			'content'			: content,
			'overlayShow'		: true,
			'overlayColor'		: '#fff',
			'title'				: title,
			'onClosed'			: onClosed,
			'showCloseButton'	: (!hideClose ? true : (hideClose ? false : true)),
			'modal'				: (!hideClose ? false : (hideClose ? true : false)),
			'centerOnScroll'	: true,
			'scrolling'			: 'no'
		});
	}
}

// Hides the modal Window
function modal_hide()
{
	jQuery.fancybox.close();
}

function hint_highlight(obj){
	if(jQuery('#' + obj).length)
	{
		if(peCount == 0)
		{
			new PeriodicalExecuter(function(pe) {
				peCount++;
				if(jQuery('#' + obj).style.color == 'rgb(255, 0, 0)')
				{
					jQuery('#' + obj).style.color="#333333";
					jQuery('#' + obj).style.border="1px solid #cccccc";
				}
				else
				{
					jQuery('#' + obj).style.color="#ff0000";
					jQuery('#' + obj).style.border="1px solid #ff0000";
				}
		
				if(peCount == 8)
				{
					pe.stop();
					peCount = 0;
				}
			}, 0.50);
		}
	}
}

// this function and variable serves for hiding loading spinner on next ajax call.
// if _ajaxHideLoadingForNextCall is true, then spinner is not shown. On each call this variable should be set to false
var _ajaxHideLoadingForNextCall = false;
function ajaxHideLoadingForNextCall() {
	_ajaxHideLoadingForNextCall = true;
}

var jquery_responder = true;
window.addEventListener("load", function() {
	var busy = jQuery('#busy');
    jQuery(document).ajaxStart(function() {
        if(jquery_responder === true && busy !== null && busy.css('display') === 'none' && !_ajaxHideLoadingForNextCall) {
            busy.show();
        }
		// reset _ajaxHideLoadingForNextCall to false so loading spinner can be displayed on next ajax call
		_ajaxHideLoadingForNextCall = false;
	});

    jQuery(document).ajaxStop(function() {
        if(jquery_responder === true && busy !== null && busy.css('display') !== 'none') {
            busy.hide();
		}
		jquery_responder = true;
	});

	jQuery(document).ajaxComplete(function() {
		if(jquery_responder === true && busy !== null && busy.css('display') !== 'none') {
			busy.hide();
		}
		jquery_responder = true;
	});
});

function unregisterJqueryResponder() {
	jquery_responder = false;
}

function updateCounties(county_element_id, id)
{
}

/**
 * decodes the given string 
 * 
 * @param str
 * @returns string
 */
function urlDecode(str) 
{
	var result = "";

	for (var i = 0; i < str.length; i++) 
	{
    	if (str.charAt(i) == "+")
        {
    		result += " ";
        }
    	else
    	{
    		result += str.charAt(i);
    	}
	}
	
	return unescape(result);
}

/**
 * encodes the given string
 * 
 * @param str
 * @returns string
 */
function encode(str) 
{
	var result = "";
	
	for (i = 0; i < str.length; i++) 
	{
		if (str.charAt(i) == " ") 
		{
			result += "+";
		}
		else
		{
			result += str.charAt(i);
		}
	}
	
	return escape(result);
}

/**
 * this method returns a translated string, received from an ajax call
 */
function $T(identifier,s)
{
	text = "";

	jQuery.ajax({
		type: 'POST',
		url: '/ajax.php',
		async: false,
		data: 'action=translate&identifier=' + identifier + '&s=' + s,
		success: function (ajaxResult) {
			text = ajaxResult;
		}
	});

	return text;
}




/**
 * this method toggles an element and additional two elements (normally used for displaying a button or link, "show xxx" -> "hide xxx")
 */
function FL_toggle(el, el_show, el_hide)
{
	jQuery('#' + el).toggle();

	if (el_show) {
		jQuery('#' + el_show).toggle();
	}
	
	if (el_hide) {
		jQuery('#' + el_hide).toggle();
	}
}


/**
 * this method enables the button
 */
function FL_enable(el)
{
	jQuery(el).removeClass('disabled');
	jQuery(el).prop('disabled', false);
}


function FL_disable(el)
{
	jQuery(el).addClass('disabled');
	jQuery(el).prop('disabled', true);
}

/**
 * this method shows an element and works on additional two elements
 */
function FL_fade(el, el_to_show, el_to_hide)
{
	jQuery(el).fadeOut('slow');
	jQuery(el).fadeIn('slow');

	// Change two other elements
	if (el_to_show) {
		jQuery(el_to_show).show();
	}
	
	if (el_to_hide) {
		jQuery(el_to_hide).hide();
	}
}


/**
 * this method sets a session key/value pair via an ajax call
 */
function setSessionKey(k, v, url)
{
	jQuery.post("/ajax.php",
		{
			action: 'save_session',
			k: k,
			v: v
		},
		function (r) {
			if (url) {
				document.location.href = url;
			}
		});
}


function setCookie(k, v, url)
{
	if (!v || v == "false")
	{	
		v = false;
	}

	jQuery.post("/ajax.php",
		{
			action: 'set_cookie',
			k: k,
			v: v
		},
		function (r) {
			if (url) {
				document.location.href = url;
			}
		});
}


/**
 * Fade out an object
 */
function fadeout(save_message_box_name)
{
	jQuery('#' + save_message_box_name).fadeOut('slow');
}


function update_field_char_counter(maxsize, field_to_count, field_counter) 
{	 
	 // Limit the text to maxsize
	var field = jQuery('#'+field_to_count);
	 if(field.val().length > maxsize) {
		 field.val(field.val().substring(0, maxsize));
	 }
	 
	 // Update the counter
	 jQuery('#'+field_counter).html("(" + field.val().length + "/" + maxsize + ")");
	 
}

function getDevice() {
    if (/android/i.test(navigator.userAgent.toLowerCase())) {
        return 'android';
    } else if (/iphone/i.test(navigator.userAgent.toLowerCase())) {
        return 'iphone';
    } else {
        return 'desktop';
    }
}

function getPopupDeviceLink() {
    var device = getDevice();

    if (device == 'android') {
        return 'https://play.google.com/store/apps/details?id=de.freelance.app';
    } else if (device == 'iphone') {
        return 'https://itunes.apple.com/de/app/freelance.de/id624634541';
    }
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return undefined;
}

function revealFadedOutContent(element) {
	totalHeight = 20;

	let el = jQuery(element);
	let p  = el.parent();
	let up = p.parent();
	let ps = up.find("div");

	// measure how tall inside should be by adding together heights of all inside paragraphs (except read-more paragraph)
	if (el.children('span').hasClass('fa-angle-down')) {
		ps.each(function() {
			totalHeight += jQuery(jQuery(this)).outerHeight();
		});	
	} else {
		totalHeight = 50;
	}

	up.css({
			// Set height to prevent instant jumpdown when max height is removed
			"height": up.height(),
			"max-height": 9999
		}).animate({
			"height": totalHeight
		}, 500, function() {
			if (el.children('span').hasClass('fa-angle-down')){
				el.children('span').removeClass('fa-angle-down');
				el.children('span').addClass('fa-angle-up');
			} else {
				el.children('span').removeClass('fa-angle-up');
				el.children('span').addClass('fa-angle-down');
			} 
		});

	// prevent jump-down
	return false;
}

window.addEventListener("load", function() {
    jQuery(document).ajaxStop(function() {
        jQuery('.selectarea').selectpicker('refresh');
    });

    jQuery('.selectarea').on('change', function() {
        jQuery('.selectarea').selectpicker('refresh');
    });

	jQuery('form[fl-status="disabled"]').each(function() {
		jQuery('.js-unset-editable').trigger('click');
	});

    jQuery(document).tooltip({
        selector: '[data-toggle=tooltip]'
    });

	jQuery(".faded-out-box .button").on("click", function() {
		revealFadedOutContent(jQuery(this));
	});

    window.session = new Session();
    // update session
	window.session.updateWithInterval();

	if (jQuery("#session_time").length) {
		var session_time_for_registration = 15;
		var session_timer = setInterval(function(){
			session_time_for_registration -= 1;
			jQuery("#session_time").html(session_time_for_registration);

			if (session_time_for_registration < 0) {
				window.location.href = jQuery("#url_for_session_expired").html();
			} else if (session_time_for_registration < 6) {
				jQuery("#session_time_container").addClass("red bold");
			}

		}, 60000);
	}
});

/**
 * Describes the response of an ajax request.
 */
function AjaxResponse (response) {

	this.error = 0;
	this.html = "";
	this.redirect = "";
	this.params = "";

	var parsedResponse = jQuery.parseJSON(response);
	if (parsedResponse != null) {
		response = parsedResponse;
	}

	this.error = (response.error == 1) ? true : false;
	this.html = response.html;
	this.redirect = response.redirect;
	this.params = response.params;
	if (this.redirect != null && this.redirect != "") {
		document.location.href = this.redirect;
	}
}

/**
 * Simple session object.
 */
class Session {

	constructor() {
		this.url = '/ajax.php';
		let currentDate = new Date();
		this.timestamp = currentDate.getTime(); // timestamp of last session update call
		this.sessionUpdateInterval = this.generateRandomNumber(300,900) * 1000; // 5 to 10 minutes in ms
		this.timeoutId = 0; // id of timout with session update
	}

	/**
	 * Updates the session of the user periodically.
	 */
	updateWithInterval () {
		// if there is timout already running, clear it, we are going to start it again
		if (this.timeoutId > 0) {
			clearTimeout(this.timeoutId);
			this.timeoutId = 0;
		}

		// schedule new session update in sessionUpdateInterval ms
		var self = this;
		this.timeoutId = window.setTimeout(function () {
			self.update();
			self.updateWithInterval();
		}, this.sessionUpdateInterval);
	}

	resetSessionUpdateInterval() {
		// update timestamp of last session update call
		let currentDate = new Date();
		this.timestamp = currentDate.getTime();

		// schedule new update call
		this.updateWithInterval();
	}

	/**
	 * Updates the session of the currently logged in user.
	 */
	update () {
		let currentDate = new Date();
		let newTimestamp = currentDate.getTime();

		const self = this;

		if (Math.abs(newTimestamp - (this.timestamp + this.sessionUpdateInterval)) < 1000) {
			// if we are updating on time +- one second, send the request
			jQuery.ajax({
				url: this.url,
				type: 'POST',
				data: "action=update_session",
				success: function (result) {
					var response = new AjaxResponse(result);
				}
			});
			// update timestamp of last session update call
			let currentDate = new Date();
			self.timestamp = currentDate.getTime();
		} else {
			// we are not on time, skip this update call but update timestamp of last session update call
			// this means that browser was probably not running js when update call should happen
			// if there were multiple tabs/windows opened while browser was not running javascript it could send multiple
			// requests to update session at once and this could result in blocking of user by our bot detection system
			let currentDate = new Date();
			self.timestamp = currentDate.getTime();
		}
	}

	/**
	 * Generates a random number between the given minimal and maximal value.
	 */
	generateRandomNumber (min, max) {
		return Math.round(Math.random() * (max - min) + min);
	}
}

window.addEventListener("load", function() {

	/**
	 * Back to top button
	 */
	let offset = 300,
		offset_opacity = 1200,
		scroll_top_duration = 700,
		back_to_top = jQuery('.cd-top');

	//hide or show the "back to top" link
	jQuery(window).on("scroll", function(){
		if (jQuery(this).scrollTop() > offset) {
			back_to_top.addClass('cd-is-visible');
		} else {
			back_to_top.removeClass('cd-is-visible cd-fade-out');
		}
		if( jQuery(this).scrollTop() > offset_opacity ) {
			back_to_top.addClass('cd-fade-out');
		}
	});

	//smooth scroll to top
	back_to_top.on('click', function(event){
		event.preventDefault();
		jQuery('body,html').animate({
				scrollTop: 0 ,
			}, scroll_top_duration);
	});

});


function show_success_sign() {

    var template = '<svg id="speichern" class="float-right" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">'
        + '<circle class="path circle" fill="none" stroke="#7cc9d2" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>'
        + '<polyline class="path check" fill="none" stroke="#7cc9d2" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>'
        +'</svg>';

    return template;
}

/*
	Function expects url in format: product=jacket&color=blue
	and returns object out of it
 */
function urlParamsToObject(url){
	return Object.fromEntries(new URLSearchParams(url));
}

function flScrollTo(element_id, latency) {

	latency = (typeof latency == "undefined" ? 1000 : latency);
	element_id = element_id.replace(/^#/, ''); //remove # from element_id

	if (jQuery("#" + element_id).length) {
		// check that element with id exist
		jQuery('html, body').animate({
			scrollTop: jQuery("#" + element_id).offset().top
		}, latency);

	} else if (jQuery("[name=" + element_id + "]").length) {
		// check that element with name exist
		jQuery('html, body').animate({
			scrollTop: jQuery("[name=" + element_id + "]").offset().top
		}, latency);
	}

}

function submitHomepageSearchForm(type, textElementId) {
	let action = "/search/project.php";
	if (type === 1) {
		action = "/search/freelancer.php";
	}

	let textElement = jQuery("#"+textElementId);

	let form = jQuery("<form>").attr("action", action).attr("method", "post").addClass("hidden");
	form.append(
		jQuery("<input>").attr("name", "__search").val("search"),
		jQuery("<input>").attr("name", "__search_type").val(type),
		jQuery("<input>").attr("name", "__search_freetext").val(textElement.val())
	);

	textElement.after(form);

	form.submit();
}

function submitHomepageSearchOnEnter(event) {
	if (event.keyCode === 13) {
		submitHomepageSearchForm(2,jQuery(event.target).attr("id"));
	}
}

// dataLayer
window.addEventListener("load", function() {
	jQuery(document).on("click", ".btn", function(){
		var btnText = "";
		var btnUrl = "";
		var showDataLayer = false;
		if (jQuery(this).prop('nodeName') === "BUTTON") {
			btnText = jQuery(this).val();
			if (btnText === "") {
				btnText = jQuery(this).text();
			}
			showDataLayer = true;
		}
		if (jQuery(this).prop('nodeName') === "A") {
			btnText = jQuery(this).text();
			btnUrl = jQuery(this).attr("href")
			showDataLayer = true;
		}
		if (jQuery(this).prop('nodeName') === "INPUT") {
			btnText = jQuery(this).val();
			if (btnText === "") {
				btnText = jQuery(this).attr("name");
			}
			showDataLayer = true;
		}
		if (showDataLayer) {
			window.dataLayer = window.dataLayer || [];
			dataLayer.push({ event_data: null });
			dataLayer.push({
				'event': "freelance.button_click",
				'event_type': "custom",
				'event_data': {
					'button_click_text': btnText,
					'button_click_link': btnUrl,
				}
			});
		}
	})
});

window.addEventListener("load", function() {
	jQuery(document).on("click", ".btn-nav", function(){
		var btnText = "";
		var btnUrl = "";
		var showDataLayer = false;
		if (jQuery(this).prop('nodeName') === "BUTTON") {
			btnText = jQuery(this).val();
			if (btnText === "") {
				btnText = jQuery(this).text();
			}
			showDataLayer = true;
		}
		if (jQuery(this).prop('nodeName') === "A") {
			btnText = jQuery(this).text();
			btnUrl = jQuery(this).attr("href")
			showDataLayer = true;
		}
		if (jQuery(this).prop('nodeName') === "INPUT") {
			btnText = jQuery(this).val();
			if (btnText === "") {
				btnText = jQuery(this).attr("name");
			}
			showDataLayer = true;
		}
		if (showDataLayer) {
			window.dataLayer = window.dataLayer || [];
			dataLayer.push({
				'event': "freelance.button_click",
				'event_type': "custom",
				'event_data': {
					'button_click_text': btnText,
					'button_click_link': btnUrl,
				}
			});
		}
	})
});

window.addEventListener("load", function() {
	jQuery(document).on("click", ".toggle-password", function(){
		jQuery(this).toggleClass("fa-eye fa-eye-slash");
		var input = jQuery(jQuery(this).attr("toggle"));
		if (input.attr("type") === "password") {
			input.attr("type", "text");
		} else {
			input.attr("type", "password");
		}
	})
});

function dataLayerForErrorControl() {
	var errorElements = document.getElementsByClassName('error-control');
	for (var i = 0; i < errorElements.length; i++) {
		var elName = "";
		var depth = 0;
		var outerElement = errorElements[i].previousElementSibling;
		var formName = "";
		if (outerElement === null) {
			outerElement = errorElements[i].parentNode;
		}
		while (outerElement !== null && depth < 3 && elName === "") {
			var innerElements = outerElement.querySelectorAll("input, select, checkbox, textarea");
			if (innerElements.length > 0) {
				for (var e = 0; e < innerElements.length; e++) {
					elName = innerElements[e].getAttribute('name');
				}
				depth = 99; // end of while
			} else {
				depth++;
				outerElement = outerElement.parentNode;
			}
		}
		if (errorElements[i].closest('form') !== null) {
			formName = errorElements[i].closest('form').getAttribute('name');
			if (!formName) {
				formName = errorElements[i].closest('form').getAttribute('action');
			}
		}
		dataLayer.push({ event_data: null });
		dataLayer.push({
			'event': "freelance.alert",
			'event_type': "custom",
			'event_data': {
				'alert_type': "error",
				'form_name': formName,
				'element_name': elName,
				'alert_message': errorElements[i].innerHTML
			}
		});
	}
}
