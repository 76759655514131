function freelancer_add_language()
{
    var languages = jQuery('select[name="languages[]"]').map(function() {
        return jQuery(this).val();
    }).get();

    var languages_skills = jQuery('select[name="languages_skills[]"]').map(function() {
        return jQuery(this).val();
    }).get();

    jQuery.ajax({
        url: '/profile_freelancer/edit.php',
        type: 'POST',
        data: {
            action: 'add_language',
            languages: languages,
            languages_skills: languages_skills
        },
        dataType: 'json',
        success: function (json) {
            jQuery('#languages').html(json['html']);
        }
    });
}

function freelancer_remove_language(removed_language_index)
{
    var languages = jQuery('select[name="languages[]"]').map(function() {
        return jQuery(this).val();
    }).get();

    var languages_skills = jQuery('select[name="languages_skills[]"]').map(function() {
        return jQuery(this).val();
    }).get();

    jQuery.ajax({
        url: '/profile_freelancer/edit.php',
        type: 'POST',
        data: {
            action: 'remove_language',
            removed_language_index: removed_language_index,
            languages: languages,
            languages_skills: languages_skills
        },
        dataType: 'json',
        success: function (json) {
            jQuery('#languages').html(json['html']);
        }
    });
}

function profile_freelancer_load_section_form(s_section, b_force)
{
    b_force = (typeof b_force == "undefined" ? false : b_force);
    if (jQuery('#panel_' + s_section + '_form').text().trim() === '' || b_force) {
        jQuery.ajax({
            type: 'POST',
            url: '/profile_freelancer/edit.php',
            data: {
                action: 'load_section_form',
                section: s_section,
            },
            dataType: 'json',
            success: function (json) {
                if (json['error_code'] == 0) {
                    jQuery('#panel_' + s_section + '_form').html(json['result']);
                    initBootstrapSwitch();
                    initBootstrapSelect();
                    initDatePicker();
                    initMaxLengthTextArea();
                }
            }
        });
    }
}

function profile_freelancer_save_section(s_section)
{
    jQuery.ajax({
        type: 'POST',
        url: '/profile_freelancer/edit.php',
        data: {
            action: 'save_section',
            section: s_section,
            form: jQuery('#form_' + s_section).serialize(),
        },
        dataType: 'json',
        success: function (json) {

            if (json['error_code'] == 0) {
                modal.show_message(json['message'], 5000, 'success');
            }

            showDataLayerAfterProfileUpdate();

            flScrollTo('#main_panel_' + s_section, 500);
            jQuery('#header_' + s_section + '_container').html(json['result']['section_progress']);
            jQuery('a.section-tags[href="#panel_' + s_section + '"]').html(json['result']['section_tags']);
            jQuery('#panel_' + s_section + '_form').html(json['result']['form']);
            jQuery('#profile_edit_header').html(json['result']['profile_edit_header']);
            jQuery('[href="' + '#panel_' + s_section + '"').click(function() {
                profile_freelancer_load_section_form(s_section);
            });

            initBootstrapSwitch();
            initBootstrapSelect();
            initDatePicker();
        }
    });
}

function profile_freelancer_save_availability(s_form)
{
    jQuery.ajax({
        type: 'POST',
        url: '/profile_freelancer/edit.php',
        data: {
            action: 'save_availability',
            form: jQuery('#' + s_form).serialize(),
        },
        dataType: 'json',
        success: function (json) {
            if (json['error_code'] == 0) {
                modal.show_message(json['message'], 5000, 'success');
                jQuery("#block_availability").html(json['result']['availability']);
                jQuery('#header_business_data_container').html(json['result']['section_progress']);
                jQuery('a.section-tags[href="#panel_business_data"]').html(json['result']['section_tags']);
                jQuery('#profile_edit_header').html(json['result']['profile_edit_header']);
            } else {
                jQuery('#' + s_form).html(json['result']);
            }

            initBootstrapSwitch();
            initBootstrapSelect();
            initDatePicker();
        }
    });
}

function profile_freelancer_save_education(s_form)
{
    jQuery.ajax({
        type: 'POST',
        url: '/profile_freelancer/edit.php',
        data: {
            action: 'save_education',
            form: jQuery('#' + s_form).serialize(),
        },
        dataType: 'json',
        success: function (json) {
            if (json['error_code'] == 0) {
                modal.show_message(json['message'], 5000, 'success');
                jQuery("#panel_education_form").html(json['result']['education']);
                jQuery('#header_education_container').html(json['result']['section_progress']);
                jQuery('a.section-tags[href="#panel_education"]').html(json['result']['section_tags']);
                jQuery('#profile_edit_header').html(json['result']['profile_edit_header']);
            } else {
                jQuery('#' + s_form).html(json['result']['form']);
            }

            initBootstrapSwitch();
            initBootstrapSelect();
            initDatePicker();
        }
    });
}

function toggle_education_type(s_form)
{
    if (jQuery("#" + s_form + " #type").find(":selected").val() == "university") {
        jQuery("#data_education_degree").show();
        jQuery("#data_education_city").show();
    } else {
        jQuery("#data_education_degree").hide();
        jQuery("#data_education_city").hide();
    }
}


function toggle_hour_rate()
{
    if(jQuery('#div_hour_rate_interval').css('display') == 'none')
    {
        jQuery('#' + 'hourly_rate').val('');
    }
    else
    {
        jQuery('#' + 'hourly_rate_from').val('');
        jQuery('#' + 'hourly_rate_to').val('');
    }

    jQuery('#' + 'div_hour_rate_interval').toggle();
    jQuery('#' + 'div_hour_rate').toggle();
    jQuery('#' + 'div_hour_rate_option_value').toggle();
    jQuery('#' + 'div_hour_rate_option_interval').toggle();
}

function clear_field_errors(container)
{
    jQuery('#' + container + ' div.form-group.error').removeClass('error');
    jQuery('#' + container + ' span.error-control').remove();
    jQuery('#' + container + ' div.alert.alert-danger').remove();
}

function toggle_reference_project_industry(element) {
    var industry = jQuery('#industry');
    var other_industry_name = jQuery('#other_industry_name');

    if (element.checked === true) {
        industry.prop('disabled', true);
        other_industry_name.show();
    } else {
        industry.prop('disabled', false);
        other_industry_name.hide();
    }

    industry.selectpicker('refresh');
}

function update_select_experience_year()
{
    var select_experience_year = jQuery('#experience_year');
    var experience_year = select_experience_year.val();
    var birthday = jQuery('#birthday');
    var birthday_year = String(birthday.val()).split(".")[2];

    if (!isNaN(parseFloat(birthday_year)) && isFinite(birthday_year)) {
        var init_value = parseInt(birthday_year) + 15;
        var end_value = new Date().getFullYear();

        select_experience_year.empty();

        var option = jQuery('<option></option>');
        option.text('Jahr...');
        option.attr('value', '');
        select_experience_year.append(option);

        for (i = init_value; i <= end_value; i++) {
            option = jQuery('<option></option>');
            option.attr('value', i);
            option.attr('id', 'birthday_year' + i);
            option.text(i);
            if (i == experience_year) {
                option.attr("selected",true);
            }
            select_experience_year.append(option);
        }

        jQuery('.selectarea').selectpicker('refresh');
    }

}


function freelancer_form_save(panel_id, profile_complete, message, show_partner_popup) {
    if (jQuery('a[href="#' + panel_id + '"] .profile-step-not-finished').length > 0) {
        jQuery('a[href="#' + panel_id + '"] .profile-step-not-finished').remove();
    }

    message = typeof message !== 'undefined' ? message : 'Ihre Daten wurden erfolgreich gespeichert.';

    if (profile_complete === true) {
        jQuery('.js-profile_status_switch').bootstrapSwitch('disabled', false);
        jQuery('.js-profile_status_switch').bootstrapSwitch('state', true);

        setTimeout(function() {
            message = message + "<br/><br/>Herzlichen Glückwunsch, Ihr Profil ist nun auf freelance.de sichtbar! Alle Profilfelder sind vollständig ausgefüllt.";
            modal.show_message({text: message, attr: {id: "fl_visible"}}, 15000, 'success');
        }, 500);
    } else {

        if (show_partner_popup){
            show_popup_partner_shopify();
        } else {
            modal.setBlockPage(false);
            modal.show_message(message, 5000, 'success');
        }
    }
    showDataLayerAfterProfileUpdate();
}

function show_completion_popup()
{
    setTimeout(function() {
        message = "Ihre Daten wurden erfolgreich gespeichert.<br/><br/>Herzlichen Glückwunsch, Ihr Profil ist nun auf freelance.de sichtbar! Alle Profilfelder sind vollständig ausgefüllt.";
        modal.show_message({text: message, attr: {id: "fl_visible"}}, 15000, 'success');
    }, 500);
}

// ######################################################################
// ------------------------- CATEGORY / SKILLS --------------------------
// ######################################################################

function freelancer_skills_save(form)
{
    let data_form = jQuery(form).serializeArray();

    jQuery('#panel_skills_ajax .top_skills_pin.fas.fa-thumbtack').each(function(index) {
        data_form.push({name: 'pin_skills[]', value: jQuery(this).attr('data-id')});
    });

    jQuery.post(
        '/profile_freelancer/edit/skills.php',
        data_form,
        function (json) {
            jQuery('#panel_skills_ajax').html(json['result']['html']);
            if (json['error_code'] === 0) {
                jQuery('#profile_edit_header').html(json['result']['profile_edit_header']);
                jQuery('#panel_skills').collapse('hide');
                flScrollTo('profile_edit');

                freelancer_form_save('panel_skills', json['result']['profile_complete'], 'Ihre Daten wurden erfolgreich gespeichert.', json['result']['show_partner_popup']);
            }
        },
        'json'
    );
}

// ######################################################################
// ------------------------- EDUCATION ----------------------------------
// ######################################################################

function freelancer_education_confirm_deletion(id) {
    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=freelancer_education_confirm_deletion&id=' + id,
        'Löschen',
        '');
}

function freelancer_certificate_confirm_deletion(id) {
    modal.showFLPopup(
        'request',
        '/ajax/popup_template.php',
        'action=freelancer_certificate_confirm_deletion&id=' + id,
        'Löschen',
        '');
}

function freelancer_education_delete(id) {
    jQuery.post(
        '/profile_freelancer/edit/education.php',
        {
            action: 'education_delete',
            id: id
        },
        function (json) {
            if (json['error_code'] === 0) {
                jQuery("#panel_education_form").html(json['result']['education']);
                jQuery('#header_education_container').html(json['result']['section_progress']);
                jQuery('a.section-tags[href="#panel_education"]').html(json['result']['section_tags']);
                jQuery('#profile_edit_header').html(json['result']['profile_edit_header']);
            }
        },
        'json'
    );
}

function freelancer_certificate_delete(id) {
    jQuery.post(
        '/profile_freelancer/edit/education.php',
        {
            action: 'certificate_delete',
            id: id
        },
        function (json) {
            if (json['error_code'] === 0) {
                jQuery("#panel_education_form").html(json['result']['education']);
                jQuery('#header_education_container').html(json['result']['section_progress']);
                jQuery('a.section-tags[href="#panel_education"]').html(json['result']['section_tags']);
                jQuery('#profile_edit_header').html(json['result']['profile_edit_header']);
            }
        },
        'json'
    );
}

// ######################################################################
// ------------------------- ABOUT ME -----------------------------------
// ######################################################################

function freelancer_about_save(form)
{
    jQuery.post(
        '/profile_freelancer/edit/about.php',
        jQuery(form).serialize(),
        function (json) {
            jQuery('#panel_about_ajax').html(json['html']);

            if (json['error'] === 0) {
                jQuery('#profile_edit_header').html(json['profile_edit_header']);
                jQuery('#panel_about').collapse('hide');

                freelancer_form_save('panel_about', json['profile_complete']);
            }
        },
        'json'
    );
}

// ######################################################################
// ------------------ AUTHENTICATION CERTIFICATE ------------------------
// ######################################################################

function freelancer_authentication_certificate_save(form) {
    jQuery.ajax({
        type: 'POST',
        url: '/ac/certificate.php',
        data: new FormData(form),
        dataType: 'json',
        contentType: false,
        cache: false,
        processData: false,
        success: function (json) {
            if (json['error'] === false) {
                window.location.replace('/ac/certificate.php');
            } else {
                jQuery('#certificate_error').show();
                jQuery('#certificate_information').html(json['information_inputs']);

                if (json['files_error'] === true) {
                    jQuery('#certificate_files').html(json['files_inputs']);
                } else {
                    jQuery('#certificate_files input').removeClass('error');
                    jQuery('#certificate_files .error-text').remove();
                }
            }
        }
    });
}

function confirm_availability(do_forwarding)
{
    do_forwarding = do_forwarding || false;

    jQuery.ajax({
        type: 'POST',
        url: '/profile_freelancer/edit.php',
        data: {
            action: 'confirm_availability'
        },
        dataType: 'json',
        success: function (json) {
            if (json['error_code'] == 0) {
                modal.show_message(json["message"], 5000, 'success');
            }

            if (do_forwarding) {
                if(jQuery('#' + 'forwarding_page').length && jQuery('#' + 'forwarding_page').val() != "") {
                    document.location.href=jQuery('#' + 'forwarding_page').val();
                } else {
                    document.location.href="/myfreelance/index.php";
                }
            } else {
                jQuery("#button_confirm_availability").hide();
            }
        }
    });
}

function closeCvParsingRequest(removeMessage) {
    jQuery.ajax({
        type: 'POST',
        url: '/profile_freelancer/edit.php',
        data: {
            action: 'close_cv_parsing'
        },
        dataType: 'json',
        success: function () {
            let messageElement = jQuery("#"+removeMessage);
            if (messageElement.length > 0) {
                messageElement.remove();
            }
        }
    });
}

function acceptCvParsingChanges() {
    let forms = jQuery("form[id*='form_']");
    let openedSections = [];
    let skip = ["education", "project_experience"];
    jQuery.each(forms, function(key, form) {
        let id = jQuery(form).attr("id");
        if (skip.includes(id.replace("form_",""))) {
            return; // skip this form
        }
        let dataArray = jQuery(form).serializeArray();
        let data = {};
        for (let i=0; i<dataArray.length; i++) {
            let name = dataArray[i].name;
            if (name.endsWith("[]")) {
                name = name.replace("[]","");
                if (!Array.isArray(data[name])) {
                    data[name] = [];
                }
                data[name].push(dataArray[i].value);
            } else {
                data[name] = dataArray[i].value;
            }
        }
        openedSections.push({
            section: id.replace("form_",""),
            form: data
        });
    });

    jQuery.ajax({
        type: 'POST',
        url: '/profile_freelancer/edit.php',
        data: {
            action: 'accept_cv_parsing',
            sections: encodeURIComponent(JSON.stringify(openedSections))
        },
        dataType: 'json',
        success: function (json) {
            console.log(json);
            if (json.error_code === 1) {

                jQuery.each(json.result.html, function(section,elm) {
                    jQuery('#header_' + section + '_container').html(elm.section_progress);
                    jQuery('a.section-tags[href="#panel_' + section + '"]').html(elm.section_tags);
                    jQuery('#panel_' + section + '_form').html(elm.html);
                    jQuery('#profile_edit_header').html(elm.profile_edit_header);
                    jQuery('[href="' + '#panel_' + section + '"').click(function() {
                        profile_freelancer_load_section_form(section);
                    });
                });

                initBootstrapSwitch();
                initBootstrapSelect();
                initDatePicker();
                jQuery(".panel-heading.collapsed").click();
                flScrollTo('#main_panel_' + json.result.section, 500);
            } else {
                window.location.href="/profile_freelancer/edit.php?get_feedback=true";
                /*
                jQuery('#main_panel').html(json.result.html);
                flScrollTo('#profile_edit_header', 500);
                */
            }
        }
    });
}