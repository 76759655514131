class SnippetUpdater {
    constructor(data) {
        this.data = data;
    }

    updateSnippet() {
        if (this.data.additionalData.ignoreOnPage == window.location.pathname) {
            return;
        }

        if (this.data.type == "template") {
            let item = jQuery('#' + this.data.identifier);
            if (item.length > 0) {
                item.html(this.data.item);
            }
        } else if (this.data.type == "popup") {
            let self = this;
            setTimeout(function() {
                if (!Object.hasOwn(self.data.additionalData,"timeout")) {
                    self.data.additionalData.timeout = 15000;
                }
                if (!Object.hasOwn(self.data.additionalData,"popupType")) {
                    self.data.additionalData.popupType = 'success';
                }

                modal.show_message({
                        text: self.data.item,
                        attr: {id: "fl_visible"}
                    },
                    self.data.additionalData.timeout,
                    self.data.additionalData.popupType,
                    self.data.additionalData.standardButtonLabel,
                    self.data.additionalData.customButton,
                    self.data.additionalData.onclickStandardButton
                );
            }, 500);
        }

        initBootstrapSwitch();
    }
}
